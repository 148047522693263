.logoFlag {
	position: absolute;
	display: flex;

	justify-content: center;
	z-index: 20;
	text-align: center;
	border-top-left-radius: 3px;
	background: theme("colors.primary");

	clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 80%, 0 100%);

	& svg {
		margin-top: 0.75rem;
		color: white;
	}
}

.logoFlagLarge {
	padding-top: 0.75rem;
	padding-left: 1.75rem;
	padding-right: 1.75rem;
	padding-bottom: 3.5rem;
}

.logoFlagSmall {
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	padding-bottom: 1.75rem;
}

.logoFlagMap {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	padding-bottom: 1.25rem;
}
