@import "../../styles/custom-media.css";

.root {
	position: relative;
	color: var(--color-black, #000);
	display: flex;
	margin: 0 auto;
	max-width: var(--width-x-large);
}

.root a {
	color: inherit;
	text-decoration: inherit;
}

.logo {
	display: block;
}

.nav {
	display: flex;
	justify-content: flex-end;
	margin-left: 8rem;
	width: 100%;

	@media (--media-min-large) {
		justify-content: center;
		margin-left: 0;
		flex: 1;
	}
}

.navItems {
	padding: 0;
	margin: 0;
	font-weight: bold;
	text-transform: uppercase;

	@media (--media-min-medium) {
		display: flex;
		justify-content: center;
		padding: 0 1rem;
	}

	@supports (padding-top: env(safe-area-inset-top)) {
		padding-right: calc(env(safe-area-inset-right) + 1rem);
	}

	@media (--media-max-medium) {
		transition-property: opacity;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		transition-duration: 150ms;
		display: block;
		z-index: 15;
		pointer-events: none;
		opacity: 0;
		padding: 4rem 0;
		position: absolute;
		left: 0;
		top: 100%;
		width: 100%;
		background-color: var(--color-black, #000);
		color: var(--color-white, #fff);

		@nest .root[data-show-nav='true'] & {
			z-index: 15;
			pointer-events: all;
			opacity: 100;
		}
	}
}

.navItem {
	display: flex;
	white-space: nowrap;
	align-items: stretch;
	line-height: calc(var(--font-base-line-height) * 1.5rem);

	@nest & > a {
		display: block;
		position: relative;
		padding: 1.5rem 0.5rem;

		@media (--media-max-medium) {
			padding: 1rem 1.5rem;
			width: 100%;
			box-sizing: border-box;
			text-align: right;

			@nest &[data-is-active='true']::before {
				content: "";
				width: 100%;
				height: 1px;
				position: absolute;
				bottom: 0.5rem;
				left: 0;
				background-color: var(--color-white, #fff);
			}
		}
	}

	@nest .root[data-show-nav='true'] & > a[data-is-active='true']::before {
		display: none;
	}

	@media (--media-min-medium) {
		margin-left: 0.5rem;
	}
}

.showNavButton {
	appearance: none;
	border: none;
	font: inherit;
	background: none;
	color: inherit;
	margin: 0;
	padding: 1.5rem;
	outline: none;

	@media (--media-min-medium) {
		display: none;
	}
}

.socialIcons {
	@media (--media-min-medium) and (--media-max-large) {
		display: none;
	}
}

.hamburgerIcon {
	font-size: calc(var(--font-base-line-height) * 1.5rem);
	display: block;

	@media (--media-min-medium) {
		display: none;
	}
}

.submenu {
	display: flex;
	height: auto;
	justify-content: flex-end;
	z-index: 9999;

	@media (--media-min-medium) {
		position: absolute;
		display: none;
		margin-top: 84px;
	}
}
