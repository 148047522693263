@import "../styles/custom-media.css";

.root {
	position: relative;
	padding: 2rem 0;

	@media (--media-min-medium) {
		padding: 4rem 0;
	}
}

.label {
	font-size: var(--font-micro-size);
	line-height: var(--font-micro-line-height);
	letter-spacing: 0.5px;
	text-transform: uppercase;
	margin-top: 1em;
}

.label + .title {
	margin-top: 0.2em;
}

.title {
	font-size: var(--font-title3-size);
	line-height: var(--font-title3-line-height);
}

.image {
	display: block;
	width: 100%;
}

.content {
	position: relative;
	margin: 0 1.5rem;
	max-width: var(--width-medium);

	@nest & figcaption {
		width: 100%;
		box-sizing: border-box;
	}

	@media (--media-min-medium) {
		margin: 0 auto;
		padding-top: 2rem;

		@nest & figcaption {
			position: absolute;
			top: 0;
		}
	}
}

.caption {
	width: 100%;
	margin: 0 auto;
	box-sizing: border-box;

	@media (--media-min-medium) {
		max-width: calc(var(--width-small) - 3rem);
	}
}

.captionBox {
	background-color: var(--color-white);
	border: 1px solid var(--color-black);
	padding: 1.5rem;

	@media (--media-min-medium) {
		max-width: calc((var(--width-small) / 2) - 3rem);
	}
}
